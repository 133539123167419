import { useState, lazy, Suspense } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import HomePage from "../pages/HomePage";
import LectureListPage from "../pages/LectureListPage";
import CourseListPage from "../pages/CourseListPage";
import CourseSearchPage from "../pages/CourseSearchPage";
import StudentCourseListPage from "../pages/StudentCourseListPage";
import MobileHiddenSearchBar from "../components/TopBar/MobileHiddenSearchBar";
import MobileHideAppBar from "../components/TopBar/MobileTopBarSmall";
import MobileTopBar from "../components/TopBar/MobileTopBar";
import Spinner from "../components/Spinner";

const PlayerPage = lazy(() => import("../pages/PlayerPage"));
const LogInPage = lazy(() => import("../pages/LogInPage"));
const SignUpPage = lazy(() => import("../pages/SignUpPage"));
const TestPage = lazy(() => import("../pages/TestPage"));
const ActivateUser = lazy(() => import("../pages/ActivateUser"));
const ManageLecturesPage = lazy(() => import("../pages/ManageLecturesPage"));
const ManageQuizzPage = lazy(() => import("../pages/ManageQuizzPage"));
const UserSessions = lazy(() => import("../pages/UserSessions"));
const CreateUserPage = lazy(() => import("../pages/CreateUserPage"));
const CreateCoursePage = lazy(() => import("../pages/CreateCoursePage"));
const ManageCoursesPage = lazy(() => import("../pages/ManageCoursesPage"));
const EditCoursePage = lazy(() => import("../pages/EditCoursePage"));
const UpdatePasswordPage = lazy(() => import("../pages/UpdatePasswordPage"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const QuizzPage = lazy(() => import("../pages/QuizzPage"));

const StudentCourseQuizzPage = lazy(() =>
  import("../pages/StudentCourseQuizzPage")
);
const ManageSubscriptionsPage = lazy(() =>
  import("../pages/ManageSubscriptionsPage")
);
const EditUserSubscriptionsPage = lazy(() =>
  import("../pages/EditUserSubscriptionsPage")
);
const ManageCourseSubscriptionsPage = lazy(() =>
  import("../pages/ManageCourseSubscriptionsPage")
);
const ResetPasswordRequestPage = lazy(() =>
  import("../pages/ResetPasswordRequestPage")
);
const ResetPasswordPage = lazy(() => import("../pages/ResetPasswordPage"));
const AttachmentsPage = lazy(() => import("../pages/AttachmentsPage"));
const LinksPage = lazy(() => import("../pages/LinksPage"));

import TopBar from "../components/TopBar/TopBarBig";
import HideAppBar from "../components/TopBar/TopBarSmall";
import Footer from "../components/Footer/Footer";
import "./MainNavigator.css";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

export default function MainNavigator() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(state => state.auth.authenticated);
  const userRole = useSelector(state => state.user.role);
  const [search, setSearch] = useState("");
  const [showMobileSearchBar, setShowMobileSearchBar] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const hasPermission = role => {
    if (typeof role === "string") {
      return userRole === role;
    }

    return role.includes(userRole);
  };

  const handleSearch = value => {
    setSearch(value);
    navigate(`/my_courses/${value}`);
  };

  return (
    <div className="wrapper">
      <>
        {isTabletOrMobile ? (
          <>
            <MobileHideAppBar />
            <div style={{ top: 0 }}>
              <MobileTopBar
                setShowSearch={setShowMobileSearchBar}
              ></MobileTopBar>
            </div>
            <MobileHiddenSearchBar
              onSearch={handleSearch}
              search={search}
              showBar={showMobileSearchBar}
              setShowBar={setShowMobileSearchBar}
            />
          </>
        ) : (
          <div className="TopBar">
            <HideAppBar onSearch={handleSearch} search={search} />
            <TopBar onSearch={handleSearch} search={search} />
          </div>
        )}
        <div>
          <Suspense
            fallback={
              <div className="spinner-container flex justify-center items-center min-h-[50vh]">
                <Spinner />
              </div>
            }
          >
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <HomePage
                    isTabletOrMobile={isTabletOrMobile}
                    setShowMobileSearchBar={setShowMobileSearchBar}
                  />
                }
              />
              <Route exact path="/test" element={<TestPage />} />
              <Route
                path="/signup"
                element={isAuthenticated ? <Navigate to="/" /> : <SignUpPage />}
              />
              <Route
                path="/login"
                element={isAuthenticated ? <Navigate to="/" /> : <LogInPage />}
              />
              <Route
                path="/createUser"
                element={
                  isAuthenticated && hasPermission("admin") ? (
                    <CreateUserPage />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/update_password"
                element={
                  isAuthenticated ? <UpdatePasswordPage /> : <Navigate to="/" />
                }
              />
              <Route
                path="/profile"
                element={
                  isAuthenticated ? <ProfilePage /> : <Navigate to="/" />
                }
              />
              <Route
                path="/createCourse"
                element={
                  isAuthenticated && hasPermission("admin") ? (
                    <CreateCoursePage />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/lectures/:courseId/:lectureId"
                element={<PlayerPage />}
              />
              <Route
                path="/lectures/:courseId/"
                element={<LectureListPage />}
              />
              <Route path="/quizz/:courseId/" element={<QuizzPage />} />
              <Route
                path="/courses"
                element={
                  isAuthenticated && hasPermission("admin") ? (
                    <ManageCoursesPage />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/subscriptions"
                element={
                  isAuthenticated && hasPermission("admin") ? (
                    <ManageSubscriptionsPage />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/course/:courseId/attachments"
                element={
                  isAuthenticated ? <AttachmentsPage /> : <Navigate to="/" />
                }
              />
              <Route
                path="/course/:courseId/links"
                element={isAuthenticated ? <LinksPage /> : <Navigate to="/" />}
              />
              <Route
                path="/course/:courseId/subscriptions"
                element={
                  isAuthenticated && hasPermission("admin") ? (
                    <ManageCourseSubscriptionsPage />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/course/:courseId/lectures"
                element={
                  isAuthenticated && hasPermission(["admin", "teacher"]) ? (
                    <ManageLecturesPage />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/course/:courseId/quizzes"
                element={
                  isAuthenticated && hasPermission(["admin"]) ? (
                    <ManageQuizzPage />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/course/:id"
                element={
                  isAuthenticated && hasPermission("admin") ? (
                    <EditCoursePage />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/reset_password"
                element={
                  !isAuthenticated ? <ResetPasswordPage /> : <Navigate to="/" />
                }
              />
              <Route
                path="/forgot_password"
                element={
                  !isAuthenticated ? (
                    <ResetPasswordRequestPage />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/subscription/user/:id"
                element={
                  isAuthenticated && hasPermission("admin") ? (
                    <EditUserSubscriptionsPage />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/sessions"
                element={
                  isAuthenticated && hasPermission("admin") ? (
                    <UserSessions />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route path="/coursesList/:type" element={<CourseListPage />} />
              <Route
                path="/search/:searchTerm"
                element={<CourseSearchPage />}
              />
              <Route path="/activate" element={<ActivateUser />} />
              <Route
                path="/my_courses/:searchTerm"
                element={<StudentCourseListPage />}
              />
              <Route path="/my_courses" element={<StudentCourseListPage />} />
              <Route path="/quizzes" element={<StudentCourseQuizzPage />} />
              <Route path="/" element={<h1>te perdiste?</h1>} />
            </Routes>
          </Suspense>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </>
    </div>
  );
}
