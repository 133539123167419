import { ApiService } from "./base";

export default function LecturesApi() {
  const getLectures = async courseId => {
    const response = await ApiService().get(`/courses/${courseId}/lectures`);
    return response;
  };

  const getLecture = async (courseId, lectureId) => {
    const response = await ApiService().get(
      `/courses/${courseId}/lectures/${lectureId}`
    );
    return response;
  };

  const createLecture = async (courseId, body) => {
    const response = await ApiService().post(
      `/courses/${courseId}/lectures`,
      body
    );
    return response;
  };

  const updateLecture = async (lectureId, courseId, body) => {
    const response = await ApiService().put(
      `/courses/${courseId}/lectures/${lectureId}`,
      body
    );
    return response;
  };

  const deleteLecture = async (lectureId, courseId) => {
    const response = await ApiService().delete(
      `/courses/${courseId}/lectures/${lectureId}`
    );
    return response;
  };

  const deleteVideo = async (lectureId, courseId) => {
    const response = await ApiService().delete(
      `/courses/${courseId}/lectures/${lectureId}/video`
    );
    return response;
  };

  return {
    getLectures,
    getLecture,
    createLecture,
    updateLecture,
    deleteLecture,
    deleteVideo,
  };
}
